import {
  ControlPointDuplicate,
  LocalShippingOutlined,
  TaskAltOutlined,
  RestartAlt,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Button, Tooltip } from "@mui/material";
import { useShipmentDetails } from "../../context/ShipmentDetailsContext";
import DialogResetShipmentStatus from "./DialogResetShipmentStatus";
import { useState } from "react";

export function useActionables() {
  const {
    shipment,
    confirmShipment,
    isConfirmationLoading,
    isBackofficeUser,
    isLTLManager,
    resetShipmentStatus,
    isResettingShipmentLoading,
    validateShipment,
    isValidateLoading
  } = useShipmentDetails();

  const [openDialogResetStatus, setOpenDialogResetStatus] = useState(false);

  const needsValidation =
    !!(shipment?.poNumber?.length > 0) &&
    !shipment.validated &&
    ((shipment.mode == "TL" &&
      !shipment.integrations?.find(
        (integration) => integration?.target == "trinity"
      )?.status == "success") ||
      (shipment.mode == "LTL" &&
        false && // TODO Remove when migrate validation to booking method.
        shipment.integrations?.find(
          (integration) => integration?.target == "banyan"
        )?.status == "success"));

  const actionables = [
    {
      key: "confirm-shipment",
      isShown: shipment.mode === 'TL' && (isBackofficeUser || isLTLManager) && !needsValidation && !shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined />}
            onClick={confirmShipment}
            variant="outlined"
            color={"primary"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirm Shipment"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "confirmed-shipment",
      isShown:
        (isBackofficeUser || isLTLManager) && !needsValidation && shipment.integrated,
      Actionable: () => (
        <>
          <LoadingButton
            startIcon={<TaskAltOutlined />}
            disabled={true}
            variant="outlined"
            color={"success"}
            sx={{
              "&.Mui-disabled": {
                color: (theme) => theme.palette.success.darker,
                border: (theme) => theme.palette.success.darker,
                opacity: 0.8,
              },
            }}
          >
            {"Confirmed"}
          </LoadingButton>
        </>
      ),
    },
    {
      key: "reconfirm-shipment",
      isShown: (isBackofficeUser || isLTLManager) && shipment.integrated,
      Actionable: () => (
        shipment.mode === "LTL" ? <></> : <Tooltip title="Update Confirmation">
          <LoadingButton
            loading={isConfirmationLoading}
            loadingPosition="start"
            startIcon={<LocalShippingOutlined width={"2rem"} />}
            onClick={confirmShipment}
            variant="outlined"
            color={"primary"}
          >
            Reconfirm
          </LoadingButton>
        </Tooltip>
      )
    },
    {
      key: "duplicate-shipment",
      isShown: !isLTLManager,
      Actionable: () => (
        <Tooltip title='Duplicate Shipment' >
          <Button
            href={`/shipments/${shipment._id}/edit?duplicate`}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <ControlPointDuplicate />
            Duplicate
          </Button>
        </Tooltip>
      ),
    },
    {
      key: "reset-shipment-status",
      isShown:
        isBackofficeUser &&
        shipment.mode === 'LTL' &&
        shipment.integrated &&
        shipment.integrations.some(integration => integration?.target?.toLowerCase() === 'banyan'),
      Actionable: () => (
        <Tooltip title='Reset Shipment Status' >
          <LoadingButton
            onClick={() => {setOpenDialogResetStatus(true)}}
            loading={isResettingShipmentLoading}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >
            <RestartAlt />
            Reset Shipment Status
          </LoadingButton>
            <DialogResetShipmentStatus             
            onConfirm={() => {
              setOpenDialogResetStatus(false);    
              resetShipmentStatus();
            }} 
            onClose={() => { setOpenDialogResetStatus(false) }} 
            open={openDialogResetStatus} /> 
        </Tooltip>
      ),
    },
    {
      key: "mark-valid-shipment",
      isShown: isBackofficeUser && shipment.metadata?.invalidPoNumber,
      Actionable: () => (
        <Tooltip title='Mark as Valid' >
          <LoadingButton
            onClick={validateShipment}
            loading={isValidateLoading}
            sx={{
              display: 'flex',
              gap: '0.5rem',
            }}
            variant="outlined"
          >         
            Mark as Valid
          </LoadingButton>
        </Tooltip>
      ),
    },
  ];

  return actionables;
}
