export const shipmentServices = [
  {
    mode: "LTL",
    pickups: [
      {
        name: "services.airportPickup",
        key: "airportPickup",
        label: "Airport Pickup",
      },
      {
        name: "services.residentialPickup",
        key: "residentialPickup",
        label: "Residential Pickup",
      },
      {
        name: "services.liftGatePickup",
        key: "liftGatePickup",
        label: "Lift Gate Pickup",
      },
      {
        name: "services.limitedAccessPickup",
        key: "limitedAccessPickup",
        label: "Limited Access",
      },      
    ],
    stops: [
      {
        name: "services.insideDelivery",
        key: "insideDelivery",
        label: "Inside Delivery",
      },
      {
        name: "services.liftGateDelivery",
        key: "liftGateDelivery",
        label: "Lift Gate Delivery",
      },
      {
        name: "services.residentialDelivery",
        key: "residentialDelivery",
        label: "Residential Delivery",
      },
      {
        name: "services.notifyBeforeDelivery",
        key: "notifyBeforeDelivery",
        label: "Notify Before Delivery",
      },
      {
        name: "services.limitedAccessDelivery",
        key: "limitedAccessDelivery",
        label: "Limited Access",
      },
      {
        name: "services.guaranteedDelivery",
        key: "guaranteedDelivery",
        label: "Guaranteed Delivery",
      },
    ],
    services: [
      {
        name: "services.overDimensions",
        key: "overDimensions",
        label: "Over Dimensions",
      }, // TODO - Validate key
      {
        name: "services.guaranteedDelivery",
        key: "guaranteedDelivery",
        label: "Guaranteed Delivery",
      }, // TODO - Validate, its key was expedited
      {
        name: "services.expedited",
        key: "expedited",
        label: "Expedited",
      },
      {
        name: "services.protectFromFreezing",
        key: "protectFromFreezing",
        label: "Protect From Freezing",
      },
      {
        name: "services.scheduleApptCharge",
        key: "scheduleApptCharge",
        label: "Schedule Appt Charge",
      },

      {
        name: "services.hazardousCargoCharge",
        key: "hazardousCargoCharge",
        label: "Hazardous Cargo Charge",
      },
    ],
  },
  {
    mode: "TL",
    services: [
      {
        label: "Dry Van Trailer",
        name: "equipment.dryVanTrailer",
        key: "dryVanTrailer",
      },
      {
        label: "Flatbed",
        name: "equipment.flatbed",
        key: "flatbed",
      },
      {
        label: "Refrigerated Trailer",
        name: "equipment.refrigeratedTrailer",
        key: "refrigeratedTrailer",
      },
      {
        label: "Step Deck",
        name: "equipment.stepDeck",
        key: "stepDeck",
      },
      {
        label: "Air Freight",
        name: "equipment.airFreight",
        key: "airFreight",
      },      
      {
        name: "services.hazardousCargoCharge",
        key: "hazardousCargoCharge",
        label: "Hazardous Cargo Charge",
      },      
      {
        name: "services.expedited",
        key: "expedited",
        label: "Expedited",
      },
      {
        name: "equipment.stepDeckRamps",
        key: "stepDeckRamps",
        label: "Step Deck With Ramps",
      },
    ],
    pickups: [
      {
        name: "services.airportPickup",
        key: "airportPickup",
        label: "Airport Pickup",
      },
      {
        name: "services.residentialPickup",
        key: "residentialPickup",
        label: "Residential Pickup",
      },
      {
        name: "services.liftGatePickup",
        key: "liftGatePickup",
        label: "Lift Gate Pickup",
      },
      {
        name: "services.limitedAccessPickup",
        key: "limitedAccessPickup",
        label: "Limited Access",
      },   
    ],
    stops: [
      {
        name: "services.insideDelivery",
        key: "insideDelivery",
        label: "Inside Delivery",
      },
      {
        name: "services.liftGateDelivery",
        key: "liftGateDelivery",
        label: "Lift Gate Delivery",
      },
      {
        name: "services.residentialDelivery",
        key: "residentialDelivery",
        label: "Residential Delivery",
      },
      {
        name: "services.notifyBeforeDelivery",
        key: "notifyBeforeDelivery",
        label: "Notify Before Delivery",
      },
      {
        name: "services.limitedAccessDelivery",
        key: "limitedAccessDelivery",
        label: "Limited Access",
      },
    ],
  },
];
